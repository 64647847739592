import {useEffect, useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";
import qs from "query-string";

const idDoctor = 2;

async function getDoctors(id, filter) {
	const {data} = await axios.get(`/staff/full?roleId=${idDoctor}`, {
		params: {...filter, sortBy: "desc", orderBy: "id"},
	});

	return data;
}

export function useDoctors(defaultFilter) {
	const [filter, filterDoctors] = useState({
		page: 1,
		search: null,
		limit: 10,
	});

	useEffect(() => {
		filterDoctors(prev => ({...prev, ...defaultFilter}));
	}, [defaultFilter]);

	const [doctorId, doctorById] = useState(null);
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(["doctors", doctorId, filter], async () => getDoctors(doctorId, filter));

	return {data, isLoading, isError, error, filter, filterDoctors, doctorById};
}

export function useDoctorsByHospital(hospitalId) {
	const [filter, filterDoctors] = useState({
		page: 1,
		search: null,
		limit: 10,
		hospitalId,
	});
	const [doctorId, doctorById] = useState(null);
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(["doctors", doctorId, filter], async () => getDoctors(doctorId, filter));

	return {data, isLoading, isError, error, filter, filterDoctors, doctorById};
}

export function useDoctorSchedules({userId, hospitalId, page, limit, type}) {
	const fetchDoctors = () => {
		return axios
			.get(`schedule/get_public_schedule?${qs.stringify({userId, hospitalId, page, limit, type})}`)
			.then(res => res.data);
	};

	const result = useQuery({
		queryKey: userId && type && ["DOCTOR_SCHEDULES", {userId, hospitalId, page, limit, type}],
		queryFn: () => fetchDoctors(),
		refetchOnMount: false,
		refetchOnWindowFocus: true,
		enabled: !!userId && !!type,
	});

	return result;
}
