import clsx from "clsx";
import {useEffect, useRef, useState} from "react";
import {Formik} from "formik";
import _ from "lodash";
import dayjs from "dayjs";
import {prosemirrorNodeToHtml} from "remirror";
import {useSelector} from "react-redux";
import {isMobile} from "react-device-detect";
import {useParams, useHistory} from "react-router-dom";
import {EditorComponent, Remirror, useRemirror} from "@remirror/react";
import {loadLocalAssets} from "utils/loader";
import {useToast} from "hooks/useToast";
import {useAddPost, useDeletePost, useDetailPost, useEditPost} from "hooks/Doctor/Post";
import {getArticlePostBySlug, getTagPost, postTagPost} from "hooks/SuperAdmin/Article";
import {convertErrorMessageFormat} from "utils/converter";
import {CircleLoader} from "components/Loader";
import {ModalConfirmation, ModalUnplash} from "components/Modal";
import {useUploadFile} from "hooks/Misc";
import {Toast} from "components/Toast";
import {AddPostSchemaValidation} from "config/form/FormValidation/Post";

import {BASE_HTML, INITIAL_VALUES} from "./constant";
import {getDetailPostFormatedData} from "./util";
import {PostAddHeader, PostAddEditorToolbar, PostAddSideBar} from "./components";
// Extensions
import EXTENSIONS, {YoutubeField, TwitterField, UnplashButton, HTMLField, HTMLEditButton} from "./extensions";

import "@remirror/styles/core.css";
import "@remirror/styles/extension-gap-cursor.css";

const getAllCurrentNode = (state, condition) => {
	const content = state.doc.content.content;

	return content.map((item, index) => ({...item, index})).filter(condition);
};

export function PostAdd() {
	const slugCheckTimeOut = useRef();
	const unplashModalRef = useRef();
	const formikRef = useRef();
	const editorContainerRef = useRef();

	const {id} = useParams();
	const history = useHistory();
	const {showToast} = useToast();
	const {manager, setState, state, onChange} = useRemirror({
		extensions: () => EXTENSIONS,
		content: {type: "doc", content: []},
		stringHandler: "json",
	});

	const addMutation = useAddPost();
	const updateMutation = useEditPost();
	const uploadFileMutation = useUploadFile();

	const {data: detailPost, isLoading: loadingFetcing} = useDetailPost(id);
	const {mutate: deletePostById, isLoading: isDeletingData} = useDeletePost();

	const userProfile = useSelector(state => state.AuthReducer.profile);

	const [isSettingOpen, setIsSettingOpen] = useState(false);
	const [isToolbarOpen, setIsToolbarOpen] = useState(false);
	const [logoPreview, setLogoPreview] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const placeholderImageNodes = getAllCurrentNode(state, item => item.type.name === "image" && !item.attrs?.src);

	useEffect(() => {
		if (!_.isEmpty(detailPost?.data?.content)) {
			const newState = manager.createState({
				content: detailPost?.data?.content,
				stringHandler: "html",
			});

			setState(newState);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [detailPost?.data?.content]);

	const handleToggleSideMenu = (handler, newStatus) => () => {
		handler(newStatus);
	};

	const handleToggleDeleteModal = () => {
		setShowDeleteModal(true);
	};

	const handleOnBannerSelectUnplashImage = () => newImage => {
		setLogoPreview(newImage);
		formikRef.current?.setFieldValue("thumbnail", newImage);
	};

	const handleOnContentSelectUnplashImage = node => arg => newImage => {
		const tr = manager.view.state.tr.setNodeMarkup(arg, null, {
			...node.attrs,
			src: newImage,
		});

		manager.view.dispatch(tr);
	};

	const handleToggleUnplashModal = onSelectFunc => arg => {
		unplashModalRef.current?.show(onSelectFunc(arg));
	};

	const handleSetThumbnail = e => {
		const file = e.target.files[0];
		const reader = new FileReader();

		if (!file) return;
		if (!file.type.includes("image")) {
			showToast("error", "Format foto tidak support, hanya menerima format .JPG .JPEG .PNG", 3000);

			return;
		}
		if (file.size > 5242880) {
			showToast("error", "thumbnail", "Ukuran file maksimal 5 ", 3000);

			return;
		}

		reader.onload = () => {
			setLogoPreview(reader.result);
		};
		reader.readAsDataURL(file);
		formikRef.current?.setFieldValue("thumbnail", file);
	};

	const handleOnTitleChange = async event => {
		const newValue = event.target.value;
		const currentSlug = formikRef.current?.values?.input_slug ?? "";
		const convertValue = newValue.replace(/ /g, "-");

		formikRef.current?.setFieldValue("title", newValue);

		if (_.isEmpty(currentSlug)) {
			clearTimeout(slugCheckTimeOut.current);

			slugCheckTimeOut.current = setTimeout(async () => {
				try {
					const res = await getArticlePostBySlug(convertValue);

					if (res) {
						formikRef.current?.setFieldValue(
							"input_slug",
							`${convertValue}-${dayjs().format("YYYYMMDDHHmmss")}`,
						);
						formikRef.current?.setFieldValue("slug", `${convertValue}-${dayjs().format("YYYYMMDDHHmmss")}`);
					} else {
						formikRef.current?.setFieldValue("input_slug", convertValue);
						formikRef.current?.setFieldValue("slug", convertValue);
					}
				} catch (error) {
					formikRef.current?.setFieldValue("input_slug", convertValue);
					formikRef.current?.setFieldValue("slug", convertValue);
				}
			}, 1000);
		}
	};

	const handleDeleteBanner = () => {
		formikRef.current?.setFieldValue("thumbnail", "");
		setLogoPreview(null);
	};

	const handleEditorChange = data => {
		const {state, firstRender} = data;

		onChange(data);

		if (firstRender) return;

		formikRef.current?.setFieldValue(
			"content",
			BASE_HTML.replace(
				"CONTENT",
				prosemirrorNodeToHtml(state.doc).replace(/<div data-placeholder-.*?<\/div>/gs, ""),
			),
		);

		if (isMobile) setIsToolbarOpen(false);
	};

	const handleUploadThumbnail = values => {
		let formData = new FormData();

		formData.append("file", values.thumbnail);

		uploadFileMutation.mutate(formData, {
			onSuccess: result => {
				formikRef.current?.setFieldValue("thumbnail", result.data?.file ?? "");

				handleOnSubmit({...values, thumbnail: result.data?.file ?? ""});
			},
			onError: res => {
				showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				window.scrollTo(0, 0);
			},
		});
	};

	const handleOnSubmit = async values => {
		let currentTagIds = [...values.tagIds];
		const newTagIds = currentTagIds.filter(item => item.__isNew__);

		if (newTagIds.length > 0) {
			await Promise.all(newTagIds.map(item => postTagPost({tag: item.value})));

			const newTagIdsRes = await getTagPost(null, {page: 1, limit: 10});
			const newTagIdsResData = newTagIdsRes?.data?.rows ?? [];

			currentTagIds = currentTagIds.map(item => {
				if (!item?.__isNew__) return item;

				const currentTagIdsRes = newTagIdsResData.find(itm => itm.tag === item.value);

				return {label: item.label, value: currentTagIdsRes.id};
			});
		}

		if (typeof values.thumbnail === "object") {
			handleUploadThumbnail(values);

			return;
		}

		const isSchedule = dayjs(`${values.publishDate} ${values.time}`).isAfter();
		const payload = {
			title: !_.isEmpty(values.title) ? values.title : "Untitled",
			content: !_.isEmpty(values.content) ? values.content : "",
			excerpt: !_.isEmpty(values.excerpt) ? values.excerpt : "",
			thumbnail: !_.isEmpty(values.thumbnail) ? values.thumbnail : "",
			meta_title: !_.isEmpty(values.meta_title) ? values.meta_title : "",
			meta_description: !_.isEmpty(values.meta_description) ? values.meta_description : "",
			slug: !_.isEmpty(values.slug) ? values.slug : `untitled-${dayjs().format("YYYYMMDDHHmmss")}`,
			time: !_.isEmpty(values.time) ? values.time : dayjs().format("HH:mm"),
			publishDate: !_.isEmpty(values.publishDate) ? values.publishDate : dayjs().format("YYYY-MM-DD"),

			postAccess: values.postAccess,
			status: values.status === "posted" && isSchedule ? "scheduled" : values.status,
			tagIds: currentTagIds.map(item => item.value),
			authorIds: values.authorIds.map(item => item.value),

			userId: userProfile.id,
		};

		const onError = res => {
			showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
		};

		if (id) {
			updateMutation.mutate(
				{...payload, id},
				{
					onSuccess: () => {
						showToast("success", `Artikel berhasil diperbarui!`, 3000);
						history.push("/doctor/post/list");
					},
					onError,
				},
			);
		} else {
			addMutation.mutate(payload, {
				onSuccess: () => {
					showToast("success", `Artikel berhasil ditambahkan!`, 3000);
					history.push("/doctor/post/list");
				},
				onError,
			});
		}
	};

	const handleOnSave = status => () => {
		formikRef.current?.setFieldValue("status", status);

		if (status === "draft") {
			handleOnSubmit({
				...(formikRef.current?.values ?? {}),
				status,
			});

			return;
		}

		formikRef.current?.handleSubmit();
	};

	const handleOnDelete = () => {
		deletePostById(
			{id},
			{
				onSuccess: () => {
					showToast("success", `Artikel berhasil dihapus!`, 3000);
					history.push("/doctor/post/list");
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	const handleOnContainerFocus = status => (_, event) => {
		if (event.relatedTarget && event.relatedTarget.tagName === "BUTTON") {
			return;
		}

		if (editorContainerRef.current) {
			// ditambah timeout karna terdapat delay untuk component yang di-click (default library)
			setTimeout(() => {
				editorContainerRef.current.classList[status ? "add" : "remove"]("editor-container-focused");
			}, 100);
		}
	};

	const handleOnEditHTML = coord => {
		if (coord?.node) {
			const tr = manager.view.state.tr.setNodeMarkup(coord.pos, null, {
				...coord.node.attrs,
				"data-placeholder-html": true,
			});

			manager.view.dispatch(tr);
		}
	};

	const renderUnplashBtn = (node, index) => {
		return (
			<UnplashButton
				key={`unplash-btn-${index}`}
				node={node}
				state={state}
				view={manager.view}
				onClick={handleToggleUnplashModal(handleOnContentSelectUnplashImage(node))}
			/>
		);
	};

	if (id && loadingFetcing) {
		return (
			<div className="min-vh-100 overflow-hidden">
				<CircleLoader />
			</div>
		);
	}

	return (
		<Formik
			initialValues={{...INITIAL_VALUES, ...getDetailPostFormatedData(detailPost)}}
			innerRef={formikRef}
			validateOnBlur={false}
			validateOnChange={false}
			validationSchema={AddPostSchemaValidation}
			onSubmit={handleOnSubmit}>
			{formik => (
				<Remirror
					initialContent={state}
					manager={manager}
					state={state}
					onBlur={handleOnContainerFocus(false)}
					onChange={handleEditorChange}
					onFocus={handleOnContainerFocus(true)}>
					<div
						className={clsx(
							"main-wrapper editor-wrapper",
							isSettingOpen && "setting-opened",
							isToolbarOpen && "toolbar-opened",
						)}>
						<PostAddEditorToolbar onClose={handleToggleSideMenu(setIsToolbarOpen, !isToolbarOpen)} />

						<div className="page-content">
							<PostAddHeader
								isShowToggleBtn={!isSettingOpen}
								status={formik.values.status}
								onSaveBtnPress={handleOnSave("draft")}
								onSubmitBtnPress={handleOnSave("posted")}
								onToggleBtnPress={handleToggleSideMenu(setIsSettingOpen, !isSettingOpen)}
							/>

							<div className="editor-main min-vh-100">
								<div className="editor-content">
									<input
										hidden
										accept="image/*"
										id="upload-banner"
										type="file"
										onChange={handleSetThumbnail}
										onClick={() => formik.setTouched({...formik.touched, thumbnail: true})}
									/>

									{logoPreview ||
									(typeof formik.values.thumbnail === "string" &&
										!_.isEmpty(formik.values.thumbnail)) ? (
										<div className="editor-banner mb-4">
											<img
												alt="logo"
												className="img-fluid"
												src={logoPreview || formik.values.thumbnail}
											/>

											<button
												className="editor-banner-btn-remover btn"
												type="button"
												onClick={handleDeleteBanner}>
												<span className="icon-ico-delete" />
											</button>
										</div>
									) : (
										<div className="editor-banner-btn d-flex align-items-center mb-4">
											<button
												className="editor-banner-btn-icon me-3"
												onClick={handleToggleUnplashModal(handleOnBannerSelectUnplashImage)}>
												<img
													alt="logo"
													className="img-fluid"
													src={loadLocalAssets("img/ri_unsplash-fill.svg")}
												/>
											</button>
											<label className="editor-banner-btn-text" htmlFor="upload-banner">
												Add feature image
											</label>
										</div>
									)}

									<input
										className="form-control editor-banner-title-field mb-3"
										name="title"
										placeholder="Post Title"
										type="text"
										value={formik.values.title}
										onBlur={formik.handleBlur}
										onChange={handleOnTitleChange}
									/>

									<div ref={editorContainerRef} className="w-100 position-relative editor-container">
										<div className="position-relative">
											<EditorComponent />

											{!isToolbarOpen && (
												<div
													className={clsx(
														state.doc.childCount === 0
															? "position-absolute top-0 start-0 end-0"
															: "mt-4",
													)}>
													<button
														className="btn btn-editor btn-editor-toolbar-trigger"
														type="button"
														onClick={handleToggleSideMenu(
															setIsToolbarOpen,
															!isToolbarOpen,
														)}>
														<img
															alt="logo"
															className="img-fluid"
															src={loadLocalAssets("img/add.svg")}
														/>
													</button>
												</div>
											)}
										</div>

										<YoutubeField state={state} view={manager.view} />
										<TwitterField state={state} view={manager.view} />
										<HTMLField state={state} view={manager.view} />
										<HTMLEditButton state={state} view={manager.view} onClick={handleOnEditHTML} />

										{placeholderImageNodes.map(renderUnplashBtn)}
									</div>
								</div>
							</div>

							<Toast />
						</div>

						<PostAddSideBar
							{...formik}
							onClose={handleToggleSideMenu(setIsSettingOpen, !isSettingOpen)}
							onDeleteBtnPress={handleToggleDeleteModal}
						/>
					</div>

					<ModalConfirmation
						approveLabel="Ya"
						description={`Apakah Anda yakin ingin menghapus artikel?`}
						loading={isDeletingData}
						rejectLabel="Batal"
						visible={showDeleteModal}
						onApprove={handleOnDelete}
						onClose={() => setShowDeleteModal(false)}
						onReject={() => setShowDeleteModal(false)}
					/>

					<ModalUnplash ref={unplashModalRef} />
				</Remirror>
			)}
		</Formik>
	);
}
