import clsx from "clsx";
import {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {setSelectedHospital} from "store/actions/hospital";
import {logout, setDisplayProfile} from "store/actions/auth";
import {Link, useLocation} from "react-router-dom";
import {ModalConfirmation, ModalContainer} from "components/Modal";
import {getAppsMenu} from "config/menu";
import {checkCurrentPath} from "utils/string";
import {useLayoutContext} from "contexts/LayoutContextProvider";
import {loadLocalAssets} from "utils/loader";

function ModalHospital({visible, onClose = () => {}}) {
	const dispatch = useDispatch();
	const {profile: userProfile, roleId: currentSelectedRoleId} = useSelector(state => state.AuthReducer);

	const selectedHospital = useSelector(state => state.HospitalsReducer.selectedHospital);
	const [selectedHospitalId, setSelectedHospitalId] = useState(selectedHospital);

	return (
		<ModalContainer visible={visible}>
			<div className="box-popup w430" id="location">
				<div className="d-flex justify-content-between align-items-center mb-4">
					<div className="text-bold text-dark">Pilih Lokasi Praktek </div>
					<div className="for-close">
						<button className="btn btn-close" type="button" onClick={onClose} />
					</div>
				</div>

				<div className="popup-content">
					{selectedHospitalId &&
						userProfile?.staff?.rsMany
							?.filter(item => item.roleId === currentSelectedRoleId)
							.map((hospital, index) => (
								<div
									key={index}
									className="loc-items mb-3"
									onClick={() => {
										setSelectedHospitalId(hospital);
									}}>
									<input
										checked={hospital.Hospital?.id === selectedHospitalId.Hospital?.id}
										name="locations"
										readOnly={true}
										type="radio"
									/>
									<label className="label-loc" style={{cursor: "pointer"}}>
										<span className="icon-ico-h-marker me-2" />
										{hospital.Hospital?.name}
									</label>
								</div>
							))}
				</div>
				<button
					className="btn btn-orange r8 w-100"
					type="button"
					onClick={() => {
						dispatch(setSelectedHospital(selectedHospitalId));
						onClose();
					}}>
					Simpan
				</button>
			</div>
		</ModalContainer>
	);
}

function ProfileSection() {
	const dispatch = useDispatch();

	const {profile} = useSelector(state => state.AuthReducer);

	const handleDisplayProfile = () => {
		dispatch(setDisplayProfile(true));
	};

	console.log("checkProfile", profile);

	return (
		<div className="profile-container">
			<img
				alt="Avatar"
				class="img-fluid"
				src={profile?.profilePhoto || loadLocalAssets("img/default_avatar.png")}
			/>

			<div className="profile-text">
				{!profile?.staff?.doctorType ? (
					<div>
						{profile?.firstName} {profile?.lastName}
					</div>
				) : (
					<>
						<div>
							Dokter {profile?.firstName} {profile?.lastName}
						</div>
						<div>Dokter Ahli Gigi</div>
					</>
				)}
				<a onClick={handleDisplayProfile}>View Detail</a>
			</div>
		</div>
	);
}

function SelectHospital({onChange = () => {}}) {
	const selectedHospital = useSelector(state => state.HospitalsReducer.selectedHospital);

	return (
		<div className="location">
			<div className="loc-top mb-2">
				<div className="text-green ft-13">Lokasi Praktek Anda</div>
				<a className="text-orange change-loc ft-13" style={{cursor: "pointer"}} onClick={onChange}>
					Ubah
				</a>
			</div>
			<a className="link-loc change-loc" onClick={onChange}>
				<span className="icon-ico-h-marker ft-18" />
				<div className="side-desc">{selectedHospital ? selectedHospital?.Hospital?.name : "-"}</div>
			</a>
		</div>
	);
}

const menuWithSubmenuStyles = {
	background: "transparent",
	border: 0,
	outline: 0,
	width: "100%",
};

export function Sidemenu() {
	const location = useLocation();
	const dispatch = useDispatch();
	const layoutContext = useLayoutContext();

	const selectedHospital = useSelector(state => state.HospitalsReducer.selectedHospital);

	const selectedRoleId = useSelector(state => state.AuthReducer.roleId);

	const [showLogoutModal, setShowLogoutModal] = useState(false);
	const [showHospitalsModal, setShowHospitalModal] = useState(false);

	const {pathname} = location;
	const splitLocation = pathname.split("/");

	const appsMenu = getAppsMenu(selectedRoleId, selectedHospital?.unitId, selectedHospital?.divisionId);

	useEffect(() => {
		layoutContext.onSetSelectedSubmenu(null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	const handleLogout = () => {
		dispatch(logout());
		window.location.href = "/auth/login";
	};

	const renderMenu = menu => {
		if (menu.submenu?.length) {
			const isMainOpened = layoutContext.selectedShowSubmenu === menu.id;
			const isSubOpened = menu.submenu.find(item => splitLocation.includes(checkCurrentPath(item.path)));
			const isOpened = isMainOpened || (isSubOpened && !layoutContext.selectedShowSubmenu);

			return (
				<div key={menu.id} className="nested-link-side">
					<button
						className={clsx("link-side ", isOpened && "active")}
						style={menuWithSubmenuStyles}
						onClick={() => {
							layoutContext.onSetSelectedSubmenu(menu.id);
						}}>
						<span className={menu.icon} />
						<div className="side-desc side-desc-nested">
							{menu.title}
							<span style={{transform: isOpened ? "rotate(180deg)" : ""}}>&#9660;</span>
						</div>
					</button>

					{isOpened && <ul>{menu.submenu.map(renderMenu)}</ul>}
				</div>
			);
		}

		return (
			<Link
				key={menu.id}
				className={clsx(
					"link-side ",
					splitLocation[1] === "" && pathname === "/" && menu.path === "/" && "active",
					pathname !== "/" && splitLocation.includes(checkCurrentPath(menu.path)) && "active",
					menu.path === pathname && "active",
				)}
				to={menu.path}>
				<span className={menu.icon} />
				<div className="side-desc">{menu.title}</div>
			</Link>
		);
	};

	return (
		<>
			<div className="sidebar-content">
				<ProfileSection />

				{[1, 2, 3, 4, 5, 6, 7].includes(selectedRoleId) && (
					<div className="mb-4">
						<SelectHospital onChange={() => setShowHospitalModal(true)} />
					</div>
				)}

				<div className="sc-top mb-4">
					<div className="side-menu-sub-title mb-2">Menu</div>

					{appsMenu?.top?.map(renderMenu)}

					{/* <a className="link-side active" href="appointment.html">
						<span className="icon-ico-appointment" />
						<div className="side-desc">Appointment</div>
					</a>

					<div className="menu-mobile">
						<div className="link-side">
							<span className="icon-ico-comment" />
							<div className="side-desc">
								<div className="d-flex justify-content-between align-items-center">
									<a className="text-grey" href="forum.html">
										Forum
									</a>
									<div className="nav-top-dropdown">
										<a
											aria-expanded="false"
											className="btn btn-top-nav px-1 py-0"
											data-bs-toggle="dropdown"
											href="#"
											id="drop-1"
											type="button">
											<span className="icon-ico-chev-down ft-13" />
										</a>
										<div aria-labelledby="drop-1" className="dropdown-menu dropdown-menu-end">
											<a className="dropdown-item" href="forum-kategori.html">
												Ferlitilitas
											</a>
											<a className="dropdown-item" href="forum-kategori.html">
												Pola Hidup Seimbang
											</a>
											<a className="dropdown-item" href="forum-kategori.html">
												Covid-19
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="link-side">
							<span className="icon-ico-comment" />
							<div className="side-desc">
								<div className="d-flex justify-content-between align-items-center">
									<a className="text-grey" href="artikel.html">
										Artikel
									</a>
									<div className="nav-top-dropdown">
										<a
											aria-expanded="false"
											className="btn btn-top-nav px-1 py-0"
											data-bs-toggle="dropdown"
											id="drop-1"
											type="button">
											<span className="icon-ico-chev-down ft-13" />
										</a>
										<div aria-labelledby="drop-1" className="dropdown-menu dropdown-menu-end">
											<a className="dropdown-item" href="artikel-kategori.html">
												Ferlitilitas (promil)
											</a>
											<a className="dropdown-item" href="artikel-kategori.html">
												Kesehatan Umum
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<a className="link-side" href="update.html">
							<span className="icon-ico-comment" />
							<div className="side-desc">Berita Terkini</div>
						</a>
					</div> */}
				</div>

				<div className="sc-bottom">
					{/* <a className="link-side" href="schedule.html">
						<span className="icon-ico-schedule" />
						<div className="side-desc">Schedule</div>
					</a>
					<a className="link-side" href="withdraw.html">
						<span className="icon-ico-card" />
						<div className="side-desc">Withdraw</div>
					</a>
					<a className="link-side" href="report.html">
						<span className="icon-ico-report" />
						<div className="side-desc">Report</div>
					</a> */}

					<div className="side-menu-sub-title mb-2">Pengaturan</div>

					{appsMenu?.bottom?.map(renderMenu)}

					<a className="link-side" href="#" onClick={() => setShowLogoutModal(true)}>
						<span className="icon-ico-logout" />
						<div className="side-desc">Logout</div>
					</a>
				</div>
			</div>

			<ModalHospital visible={showHospitalsModal} onClose={() => setShowHospitalModal(false)} />
			<ModalConfirmation
				approveLabel="Ya"
				description="Apakah anda yakin ingin keluar dari aplikasi?"
				rejectLabel="Batal"
				title="Konfirmasi Keluar"
				visible={showLogoutModal}
				onApprove={handleLogout}
				onClose={() => setShowLogoutModal(false)}
				onReject={() => setShowLogoutModal(false)}
			/>
		</>
	);
}
