import {BlockquoteExtension} from "remirror/extensions";

class CustomBlockquoteExtension extends BlockquoteExtension {
	createNodeSpec(extra, override) {
		const baseSpec = super.createNodeSpec(extra, override);

		return {
			...baseSpec,
			parseDOM: [
				{
					tag: "blockquote.styled-quote",
					getAttrs: () => {},
				},
			],
			toDOM: () => {
				return ["blockquote", {class: "styled-quote"}, 0];
			},
		};
	}
}

export const Blockquote = new CustomBlockquoteExtension();
