import {BoldExtension, ItalicExtension} from "remirror/extensions";

import {ImageUpload, UnplashButton} from "./ImageUpload";
import {FileUpload} from "./FileUpload";
import {VideoUpload} from "./VideoUpload";
import {Blockquote} from "./Blockquote";
import {Paragraph} from "./Paragraph";
import {Heading} from "./Heading";
import {Callout, CalloutHeader, CalloutContent} from "./Callout";
import {Toggle, ToggleHeader, ToggleContent} from "./Toggle";
import {Youtube, YoutubeField} from "./Youtube";
import {Twitter, TwitterField} from "./Twitter";
import {HTML, HTMLField, HTMLEditButton} from "./Html";

const EXTENSIONS = [
	new BoldExtension(),
	new ItalicExtension(),

	// Custom Extensions
	Paragraph,
	ImageUpload,
	VideoUpload,
	FileUpload,
	Blockquote,
	Heading,
	Youtube,
	Twitter,
	Callout,
	CalloutHeader,
	CalloutContent,
	Toggle,
	ToggleHeader,
	ToggleContent,
	HTML,
];

export {YoutubeField, TwitterField, UnplashButton, HTMLField, HTMLEditButton};
export default EXTENSIONS;
